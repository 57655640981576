// Libraries
// @ts-expect-error library is not typed
import {useMediaQuery} from 'react-responsive';

// Supermove
import {ResponsiveType, useMemo} from '@supermove/hooks';

const defaults = {
  desktop: false,
  tablet: false,
  mobile: false,
};

const useResponsive = (): ResponsiveType => {
  const isLargeDesktop = useMediaQuery({minWidth: 1200});
  const isDesktop = useMediaQuery({minWidth: 1032});
  const isTablet = useMediaQuery({minWidth: 768});

  return useMemo(() => {
    if (isLargeDesktop) {
      return {...defaults, desktop: true, large: true};
    } else if (isDesktop) {
      return {...defaults, desktop: true};
    } else if (isTablet) {
      return {...defaults, tablet: true};
    } else {
      // Anything smaller is mobile.
      return {...defaults, mobile: true};
    }
  }, [isLargeDesktop, isDesktop, isTablet]);
};

export default useResponsive;
